<template>
  <span class="d-inline-block text-truncate min-w-200px max-w-200px">
    {{ value }}
  </span>
</template>

<script>
  export default {
    name: 'AppTextTruncateContainer',
    props: {
      value: {
        type: String
      }
    }
  };
</script>

<style></style>
