<template>
  <div class="app-smart-form-field">
    <div>
      <!--begin::Input Text-->
      <InputText
        v-if="type == smartFormFieldType.TEXT"
        type="text"
        :value="value"
        @input="input"
        class="w-100"
        :class="{ invalid: isValidate && validation.isError }"
      />
      <!--end::Input Text-->

      <!--begin::Dropdown-->
      <Dropdown
        v-else-if="type == smartFormFieldType.SELECT"
        :value="value"
        :options="options"
        :showClear="Boolean(value)"
        :optionLabel="optionLabel"
        class="w-100"
        :class="{ invalid: isValidate && validation.isError }"
        @input="input"
      />
      <!--end::Dropdown-->

      <!--begin::Multi Select-->
      <MultiSelect
        v-else-if="type == smartFormFieldType.MULTI_SELECT"
        :value="value"
        :options="options"
        :optionLabel="optionLabel"
        display="chip"
        class="w-100"
        :class="{ invalid: isValidate && validation.isError }"
        @input="input"
      />
      <!--end::Multi Select-->

      <!--begin::Date Range Overlay Panel-->
      <div
        v-else-if="type == smartFormFieldType.DATE_RANGE"
        class="p-inputgroup"
      >
        <span class="p-inputgroup-addon">
          <i class="pi pi-calendar"></i>
        </span>
        <InputText
          readonly="readonly"
          type="text"
          :value="dateRange"
          class="w-100 date-picker-input cursor-pointer"
          :class="{ invalid: isValidate && validation.isError }"
          @click="openDateRangePicker"
        />
        <OverlayPanel
          ref="dateRangeOverlayPanel"
          appendTo="body"
          class="date-picker-overlay-panel"
        >
          <vue-ctk-date-time-picker
            :value="value"
            format="YYYY-MM-DD"
            formatted="l"
            label=""
            color="var(--primary)"
            button-color="var(--primary)"
            inline
            no-label
            only-date
            range
            :min-date="minDate"
            :max-date="maxDate"
            @input="input"
          ></vue-ctk-date-time-picker>
          <div class="separator separator-solid"></div>
          <div class="d-flex justify-content-end py-3 px-4">
            <b-button
              variant="white"
              size="sm"
              class="mr-2"
              @click="clearDateRange"
              >{{ $t('label.clear') }}</b-button
            >
            <b-button
              variant="primary"
              size="sm"
              @click="actionApplyDateRange"
              >{{ $t('label.apply') }}</b-button
            >
          </div>
        </OverlayPanel>
      </div>
      <!--end::Date Range Overlay Panel-->
    </div>

    <!--begin::Invalid Message-->
    <div class="invalid-message-wrapper">
      <small v-show="isValidate && validation.isError" class="invalid">{{
        validation.message
      }}</small>
    </div>
    <!--end::Invalid Message-->
  </div>
</template>

<script>
  import { SMART_FORM_FIELD_TYPE } from '@/core/constants';

  export default {
    name: 'AppSmartFormField',
    props: {
      value: {},
      type: {
        type: String,
        default: 'text'
      },
      rules: {
        type: Function,
        default: null
      },
      isOptional: {
        type: Boolean,
        default: false
      },
      options: {
        type: Array,
        default: () => []
      },
      optionLabel: {
        type: String,
        default: 'text'
      },
      minDate: {
        type: String,
        default: ''
      },
      maxDate: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      isValidate: false,
      smartFormFieldType: SMART_FORM_FIELD_TYPE
    }),
    computed: {
      validation() {
        let value = this.value;
        let isOptional = this.isOptional;

        if (this.rules) {
          let rules = this.rules(value);
          let isError = rules.map((x) => {
            return Boolean(x);
          });

          if ((isOptional && value) || !isOptional) {
            return {
              isError: isError.includes(true),
              message: rules.filter((x) => {
                return x != false;
              })[0]
            };
          } else {
            return {
              isError: false,
              message: ''
            };
          }
        } else {
          return {
            isError: false,
            message: ''
          };
        }
      },
      dateRange() {
        let date = this.value;
        return date ? date.start + ' ~ ' + (date.end ? date.end : '') : '';
      }
    },
    methods: {
      convertDateRange(value) {
        return [value.start, value.end];
      },
      clearDateRange() {
        this.input(null);
        this.$refs.dateRangeOverlayPanel.hide();
      },
      actionApplyDateRange() {
        let date = this.value;

        if (date && !date.end) {
          date.end = date.start;
          this.input(date);
        }

        this.$refs.dateRangeOverlayPanel.hide();
      },
      openDateRangePicker(event) {
        this.$refs.dateRangeOverlayPanel.toggle(event);
      },
      reset() {
        this.$emit('input', null);
        this.isValidate = false;
      },
      validate() {
        this.isValidate = true;
      },
      input(value) {
        this.isValidate = true;
        this.$emit('input', value);
      }
    }
  };
</script>

<style lang="scss">
  .app-smart-form-field {
    .invalid-message-wrapper {
      min-height: 20px;
    }
    .invalid {
      color: var(--danger);
    }
    .date-picker-input {
      &.p-component:disabled {
        opacity: unset !important;
      }
    }
  }
  .date-picker-overlay-panel {
    width: 450px;
    &.p-overlaypanel .p-overlaypanel-content {
      padding: 0 !important;
    }
  }

  @media (max-width: 599px) {
    .date-picker-overlay-panel {
      width: 400px;
    }
  }

  @media (max-width: 499px) {
    .date-picker-overlay-panel {
      width: 300px;
    }
  }
</style>
