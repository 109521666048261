import commonMixin from '@/core/mixins/common.mixin';
import { i18nHelper } from '@/core/utils';

const crudMixin = {
  mixins: [commonMixin],
  computed: {
    readListRecordComplete() {
      let obj = this.actions.readListRecord;

      if (obj) {
        return this.$store.state[obj.module][obj.state].complete;
      } else {
        return false;
      }
    },
    createRecordComplete() {
      let obj = this.actions.createRecord;

      if (obj) {
        return this.$store.state[obj.module][obj.state].complete;
      } else {
        return false;
      }
    },
    readRecordComplete() {
      let obj = this.actions.readRecord;

      if (obj) {
        return this.$store.state[obj.module][obj.state].complete;
      } else {
        return false;
      }
    },
    updateRecordComplete() {
      let obj = this.actions.updateRecord;

      if (obj) {
        return this.$store.state[obj.module][obj.state].complete;
      } else {
        return false;
      }
    },
    deleteRecordComplete() {
      let obj = this.actions.deleteRecord;

      if (obj) {
        return this.$store.state[obj.module][obj.state].complete;
      } else {
        return false;
      }
    },
    readListRecordWithIdComplete() {
      let obj = this.actions.readListRecordWithId;

      if (obj) {
        return this.$store.state[obj.module][obj.state].complete;
      } else {
        return false;
      }
    },
    readRecordWithExtraIdComplete() {
      let obj = this.actions.readRecordWithExtraId;

      if (obj) {
        return this.$store.state[obj.module][obj.state].complete;
      } else {
        return false;
      }
    }
  },
  watch: {
    readListRecordComplete() {
      let obj = this.actions.readListRecord;
      let response = this.$store.state[obj.module][obj.state];

      if (response) {
        if (response.complete) {
          this.readListRecordCompleteAction(response, obj);
        }
      }
    },
    createRecordComplete() {
      let obj = this.actions.createRecord;
      let response = this.$store.state[obj.module][obj.state];

      if (response) {
        if (response.complete) {
          this.createRecordCompleteAction(response, obj);
        }
      }
    },
    readRecordComplete() {
      let obj = this.actions.readRecord;
      let response = this.$store.state[obj.module][obj.state];

      if (response) {
        if (response.complete) {
          this.readRecordCompleteAction(response, obj);
        }
      }
    },
    updateRecordComplete() {
      let obj = this.actions.updateRecord;
      let response = this.$store.state[obj.module][obj.state];

      if (response) {
        if (response.complete) {
          this.updateRecordCompleteAction(response, obj);
        }
      }
    },
    deleteRecordComplete() {
      let obj = this.actions.deleteRecord;
      let response = this.$store.state[obj.module][obj.state];

      if (response) {
        if (response.complete) {
          this.deleteRecordCompleteAction(response, obj);
        }
      }
    },
    readListRecordWithIdComplete() {
      let obj = this.actions.readListRecordWithId;
      let response = this.$store.state[obj.module][obj.state];

      if (response) {
        if (response.complete) {
          this.readListRecordWithIdCompleteAction(response, obj);
        }
      }
    },
    readRecordWithExtraIdComplete() {
      let obj = this.actions.readRecordWithExtraId;
      let response = this.$store.state[obj.module][obj.state];

      if (response) {
        if (response.complete) {
          this.readRecordWithExtraIdCompleteAction(response, obj);
        }
      }
    }
  },
  methods: {
    readListRecordCompleteAction(response, obj) {
      let title = obj.title;

      if (response.code == 200) {
        obj.successAction(response);
      } else {
        this.openErrorLayoutAppModalInfo(title, response);
      }

      this.initialReadListRecordState();
    },
    createRecordCompleteAction(response, obj) {
      let title = obj.title;
      let addOnOkAction = obj.successAction;

      if (response.code == 200) {
        this.openSuccessLayoutAppModalInfo(title, response, addOnOkAction);
      } else {
        this.openErrorLayoutAppModalInfo(title, response);
      }

      this.initialCreateRecordState();
    },
    readRecordCompleteAction(response, obj) {
      let title = obj.title;

      if (response.code == 200) {
        obj.successAction(response);
      } else {
        this.openErrorLayoutAppModalInfo(title, response);
      }

      this.initialReadRecordState();
    },
    updateRecordCompleteAction(response, obj) {
      let title = obj.title;
      let addOnOkAction = obj.successAction;

      if (response.code == 200) {
        this.openSuccessLayoutAppModalInfo(title, response, addOnOkAction);
      } else {
        this.openErrorLayoutAppModalInfo(title, response);
      }

      this.initialUpdateRecordState();
    },
    deleteRecordCompleteAction(response, obj) {
      let title = obj.title;
      let addOnOkAction = obj.successAction;

      if (response.code == 200) {
        this.openSuccessLayoutAppModalInfo(title, response, addOnOkAction);
      } else {
        this.openErrorLayoutAppModalInfo(title, response);
      }

      this.initialDeleteRecordState();
    },
    readListRecordWithIdCompleteAction(response, obj) {
      let title = obj.title;

      if (response.code == 200) {
        obj.successAction(response);
      } else {
        this.openErrorLayoutAppModalInfo(title, response);
      }

      this.initialReadListRecordWithIdState();
    },
    readRecordWithExtraIdCompleteAction(response, obj) {
      let title = obj.title;

      if (response.code == 200) {
        obj.successAction(response);
      } else {
        this.openErrorLayoutAppModalInfo(title, response);
      }

      this.initialReadRecordWithExtraIdState();
    },
    requestCreate(data) {
      let obj = this.actions.createRecord;
      let title = obj.title;
      let messages = [obj.message];
      let buttons = [];

      buttons.push({
        text: i18nHelper.getMessage('text.cancel'),
        outlined: true,
        action: () => {
          this.closeLayoutAppModalInfo();
        }
      });

      buttons.push({
        text: i18nHelper.getMessage('text.confirm'),
        variant: 'primary',
        action: () => {
          this.createRecord(data);
          this.closeLayoutAppModalInfo();
        }
      });

      this.openLayoutAppModalInfo(title, messages, buttons);
    },
    requestUpdate(id, data) {
      let obj = this.actions.updateRecord;
      let title = obj.title;
      let messages = [obj.message];
      let buttons = [];

      buttons.push({
        text: i18nHelper.getMessage('text.cancel'),
        outlined: true,
        action: () => {
          this.closeLayoutAppModalInfo();
        }
      });

      buttons.push({
        text: i18nHelper.getMessage('text.confirm'),
        variant: 'primary',
        action: () => {
          this.updateRecord(id, data);
          this.closeLayoutAppModalInfo();
        }
      });

      this.openLayoutAppModalInfo(title, messages, buttons);
    },
    requestDelete(id) {
      let obj = this.actions.deleteRecord;
      let title = obj.title;
      let messages = [obj.message];
      let buttons = [];

      buttons.push({
        text: i18nHelper.getMessage('label.cancel'),
        variant: 'white',
        action: () => {
          this.closeLayoutAppModalInfo();
        }
      });

      buttons.push({
        text: i18nHelper.getMessage('label.delete'),
        variant: 'danger',
        action: () => {
          this.deleteRecord(id);
          this.closeLayoutAppModalInfo();
        }
      });

      this.openLayoutAppModalInfo(title, messages, buttons);
    },
    readListRecord(data) {
      this.$store.dispatch(this.actions.readListRecord.action, { data });
    },
    createRecord(data) {
      this.$store.dispatch(this.actions.createRecord.action, { data });
    },
    readRecord(id) {
      this.$store.dispatch(this.actions.readRecord.action, { id });
    },
    updateRecord(id, data) {
      this.$store.dispatch(this.actions.updateRecord.action, { id, data });
    },
    deleteRecord(id) {
      this.$store.dispatch(this.actions.deleteRecord.action, { id });
    },
    readListRecordWithId(id, data) {
      this.$store.dispatch(this.actions.readListRecordWithId.action, {
        id,
        data
      });
    },
    readRecordWithExtraId(parentId, id) {
      this.$store.dispatch(this.actions.readRecordWithExtraId.action, {
        parentId,
        id
      });
    },
    initialReadListRecordState() {
      this.$store.dispatch(this.actions.initialReadListRecordState);
    },
    initialCreateRecordState() {
      this.$store.dispatch(this.actions.initialCreateRecordState);
    },
    initialReadRecordState() {
      this.$store.dispatch(this.actions.initialReadRecordState);
    },
    initialUpdateRecordState() {
      this.$store.dispatch(this.actions.initialUpdateRecordState);
    },
    initialDeleteRecordState() {
      this.$store.dispatch(this.actions.deleteRecord.initialAction);
    },
    initialReadListRecordWithIdState() {
      this.$store.dispatch(this.actions.initialReadListRecordWithIdState);
    },
    initialReadRecordWithExtraIdState() {
      this.$store.dispatch(this.actions.initialReadRecordWithExtraIdState);
    }
  }
};

export default crudMixin;
