<template>
  <div class="app-smart-table">
    <DataTable 
      :value="items" 
      paginator :rows="10" 
      :rowsPerPageOptions="[10, 20, 50]" 
      rowHover
      removableSort
    >
      <template #empty>
        <div class="text-center p-2">
          <span>{{ $t('message.thereAreNoRecordsToShow') }}</span>
        </div>
      </template>
      <template v-for="(header, index) in headers">
        <Column
          :key="index"
          :field="header.key"
          :header="$t(`label.${header.name}`)"
          :sortable="header.sortable"
          :class="header.class"
          :sortField="header.sortField"
          headerClass="datatable_header"
        >
          <template #body="slotProps" v-if="header.layout">
            <component
              :is="header.layout"
              :item="slotProps.data"
              :value="slotProps.data[header.key]"
              :header="header"
              :layoutActions="header.layoutActions"
              :accessRights="accessRights"
              :config="header.config"
              @app-table-actions-read-details="$emit('app-table-read-details', $event)"
              @app-table-actions-open-modal-details="
                $emit('app-table-open-modal-details', $event)
              "
              @app-table-actions-action-delete="$emit('app-table-action-delete', $event)"
            >
            </component>
          </template>
        </Column>
      </template>
    </DataTable>
  </div>
</template>

<script>
  import AppCheckCircle from '@/views/components/bases/AppCheckCircle';
  import AppActivationStatusLabel from '@/views/components/bases/AppActivationStatusLabel';
  import AppTableActions from '@/views/components/bases/AppTableActions';
  import AppMemberTypeLabel from '@/views/components/bases/AppMemberTypeLabel';
  import AppTablePagination from '@/views/components/bases/pagination/AppTablePagination';
  import AppTextTruncateContainer from '@/views/components/bases/AppTextTruncateContainer';
  import AppStatusLabel from '@/views/components/bases/AppStatusLabel';
  import AppEnumLabel from '@/views/components/bases/AppEnumLabel';

  export default {
    name: 'AppSmartTable',
    components: {
      AppCheckCircle,
      AppActivationStatusLabel,
      AppTableActions,
      AppMemberTypeLabel,
      AppTablePagination,
      AppTextTruncateContainer,
      AppStatusLabel,
      AppEnumLabel
    },
    props: {
      items: {
        type: Array,
        default: () => []
      },
      headers: {
        type: Array,
        default: () => [
          {
            key: 'no',
            name: 'no',
            active: true
          }
        ]
      },
      accessRights: {
        type: Object,
        default: () => {
          return {
            moduleName: '',
            actions: {
              create: '',
              read: '',
              update: '',
              delete: ''
            }
          };
        }
      }
    },
    data: () => ({
    }),
    computed: {
    },
    methods: {

    }
  };
</script>

<style lang="scss">
  .app-smart-table {
    .p-datatable-wrapper {
      overflow: auto;
      .p-datatable-table {
        font-family: Poppins, Helvetica, "sans-serif";
        font-size: .925rem;
        
        .p-datatable-emptymessage:hover {
          background: none !important;
        }
        .datatable_header {
          color: #b5b5c3;
          font-size: 0.925rem;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 0.1rem;
          padding: 0.5rem 1.2rem;
          border: none;

          &.p-highlight {
            color: var(--primary) !important;
          }
        }
      }
    }
    .p-paginator.p-component.p-paginator-bottom {
      justify-content: end;
      & > .p-paginator-element, span.p-paginator-pages {
        border-radius: 5px;
        margin: 0 0.2rem
      }
      & > span.p-paginator-pages > .p-paginator-element:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      & > span.p-paginator-pages > .p-paginator-element:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
</style>
