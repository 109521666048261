<template>
  <div>
    <a
      class="btn btn-sm btn-clean btn-icon"
      @click="actionReadDetails(item.id)"
    >
      <i class="icon-nm" :class="readOrEditIcons"></i>
    </a>
    <a
      v-if="
        layoutActions.hasActionDelete &&
        checkAccessRight(accessRights.moduleName, [accessRights.actions.delete])
      "
      class="btn btn-sm btn-clean btn-icon"
      @click="actionDelete(item.id)"
    >
      <i class="far fa-trash-alt icon-nm"></i>
    </a>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'AppTableActions',
    mixins: [commonMixin],
    props: {
      item: {
        type: Object,
        default: () => {}
      },
      layoutActions: {
        type: Object,
        default: () => {
          return {
            isModal: false,
            hasActionDelete: false
          };
        }
      },
      accessRights: {
        type: Object,
        default: () => {
          return {
            moduleName: '',
            actions: {
              create: '',
              read: '',
              update: '',
              delete: ''
            }
          };
        }
      }
    },
    computed: {
      readOrEditIcons() {
        let ar = this.accessRights;

        if (this.checkAccessRight(ar.moduleName, [ar.actions.update])) {
          return 'far fa-edit';
        } else {
          return 'far fa-eye';
        }
      }
    },
    methods: {
      actionDelete(value) {
        this.$emit('app-table-actions-action-delete', value);
      },
      actionReadDetails(value) {
        if (this.layoutActions.isModal) {
          this.$emit('app-table-actions-open-modal-details', value);
        } else {
          this.$emit('app-table-actions-read-details', value);
        }
      }
    }
  };
</script>

<style></style>
