<template>
  <span
    class="label label-md label-inline"
    :class="`label-light-${value ? 'primary' : 'warning'}`"
  >
    {{ $t(`enumMemberType.${Number(value)}`) }}
  </span>
</template>

<script>
  export default {
    name: 'AppMemberTypeLabel',
    props: {
      value: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style></style>
