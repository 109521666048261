<template>
  <b-collapse v-model="visible" class="mt-2">
    <b-card
      body-class="px-0 py-5"
      class="rounded-0 border-right-0 border-left-0"
    >
      <b-card-title class="h6">
        {{ $t('label.columns') }}
      </b-card-title>
      <b-row no-gutters class="p-4 table-header-columns">
        <template v-for="(i, index) in tableHeaderList">
          <b-col :key="index" cols="12" sm="6" md="4" lg="3" xl="2">
            <b-form-checkbox
              v-model="selectedHeader"
              :disabled="checkActiveCheckBox(selectedHeader, i)"
              :value="i.key"
              size="lg"
              switches
              @change="checkBoxChange"
            >
              <div class="font-size-sm pt-1 text-capitalize">
                {{ $t(`label.${i.name}`) }}
              </div>
            </b-form-checkbox>
          </b-col>
        </template>
      </b-row>
    </b-card>
  </b-collapse>
</template>

<script>
  export default {
    name: 'AppTablePropertiesCollapse',
    props: {
      tableHeaderList: {
        type: Array,
        default: () => []
      },
      visible: {
        type: Boolean
      }
    },
    data: () => ({
      selectedHeader: []
    }),
    created() {
      this.initAppTablePropertiesCollapse();
    },
    methods: {
      checkActiveCheckBox(value, data) {
        let disabled = false;

        if (value.length == 1 && value.includes(data.key)) {
          disabled = true;
        }

        return disabled;
      },
      initAppTablePropertiesCollapse() {
        this.selectedHeader = this.tableHeaderList.map((x) => x.key);
        this.checkBoxChange(this.selectedHeader);
      },
      checkBoxChange(value) {
        this.$emit('change', value);
      }
    }
  };
</script>

<style lang="scss"></style>
