<template>
  <label
    class="label label-md label-inline"
    :class="`label-light-${config.statusColor(value)}`"
  >
    {{ $t(`${config.enumKey}.${value}`) }}
  </label>
</template>

<script>
  export default {
    name: 'AppStatusLabel',
    props: {
      value: {
        type: [Number, String],
        default: null
      },
      config: {
        type: Object,
        default: () => {
          return {
            enumKey: '',
            statusColor: (value) => {
              let color = {
                1: 'primary',
                2: 'warning',
                3: 'danger'
              };

              return color[value];
            }
          };
        }
      }
    }
  };
</script>

<style></style>
